import React from 'react';
const UpdatesPanel = () => {
  return (
    <div className='container-updates'>
      <div className='updates-unit'>
        <h3> New Breaches </h3>
      </div>
      <div className='updates-item'>
        <div> Today: </div>
        <div> Number Value</div>
      </div>
      <div className='updates-item'>
        <div> This Week: </div>
        <div> Number Value</div>
      </div>
      <div className='updates-item'>
        <div> This Year: </div>
        <div> Number Value</div>
      </div>
    </div>
  )
};
export default UpdatesPanel;