import React from 'react';
import ReactDOM from 'react-dom';
import Dashboard from './Dashboard';
//calls the app file to run the websitre 
ReactDOM.render(
  <React.StrictMode>
    <Dashboard />
  </React.StrictMode>,
  document.getElementById('root')
  //it will insert all the content in the root class
  // that you can see in the index.HTML file
);