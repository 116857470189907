export const STATES = [
    {
        state: "Alabama",
        abbreviation: "AL"
    },
    {
        state: "Alaska",
        abbreviation: "AK"
    },
    {
        state: "Arizona",
        abbreviation: "AZ"
    },
    {
        state: "Arkansas",
        abbreviation: "AR"
    },
    {
        state: "California",
        abbreviation: "CA"
    },
    {
        state: "Colorado",
        abbreviation: "CO"
    },
    {
        state: "Connecticut",
        abbreviation: "CT"
    },
    {
        state: "Delaware",
        abbreviation: "DE"
    },
    {
        state: "Florida",
        abbreviation: "FL"
    },
    {
        state: "Georgia",
        abbreviation: "GA"
    },
    {
        state: "Hawaii",
        abbreviation: "HI"
    },
    {
        state: "Idaho",
        abbreviation: "ID"
    },
    {
        state: "Illinois",
        abbreviation: "IL"
    },
    {
        state: "Indiana",
        abbreviation: "IN"
    },
    {
        state: "Iowa",
        abbreviation: "IA"
    },
    {
        state: "Kansas",
        abbreviation: "KS"
    },
    {
        state: "Kentucky",
        abbreviation: "KY"
    },
    {
        state: "Louisiana",
        abbreviation: "LA"
    },
    {
        state: "Maine",
        abbreviation: "ME"
    },
    {
        state: "Maryland",
        abbreviation: "MD"
    },
    {
        state: "Massachusetts",
        abbreviation: "MA"
    },
    {
        state: "Michigan",
        abbreviation: "MI"
    },
    {
        state: "Minnesota",
        abbreviation: "MI"
    },
    {
        state: "Mississippi",
        abbreviation: "MS"
    },
    {
        state: "Misouri",
        abbreviation: "MO"
    },
    {
        state: "Montana",
        abbreviation: "MT"
    },
    {
        state: "Nebraska",
        abbreviation: "NE"
    },
    {
        state: "Navada",
        abbreviation: "NV"
    },
    {
        state: "Arkansas",
        abbreviation: "AR"
    },
    {
        state: "New Hampshire",
        abbreviation: "NH"
    },
    {
        state: "New Jersey",
        abbreviation: "NJ"
    },
    {
        state: "New Mexico",
        abbreviation: "NM"
    },
    {
        state: "New York",
        abbreviation: "NY"
    },
    {
        state: "North Carolina",
        abbreviation: "NC"
    },
    {
        state: "North Dakota",
        abbreviation: "ND"
    },
    {
        state: "Ohio",
        abbreviation: "OH"
    },
    {
        state: "Oklahoma",
        abbreviation: "OK"
    },
    {
        state: "Oregon",
        abbreviation: "OR"
    },
    {
        state: "Pennsylvania",
        abbreviation: "PA"
    },
    {
        state: "Rhode Island",
        abbreviation: "RI"
    },
    {
        state: "South Carolina",
        abbreviation: "SC"
    },
    {
        state: "South Dakota",
        abbreviation: "SD"
    },
    {
        state: "Tennessee",
        abbreviation: "TN"
    },
    {
        state: "Texas",
        abbreviation: "TX"
    },
    {
        state: "Utah",
        abbreviation: "UT"
    },
    {
        state: "Vermont",
        abbreviation: "VT"
    },
    {
        state: "Virginia",
        abbreviation: "VA"
    },
    {
        state: "Washington",
        abbreviation: "WA"
    },
    {
        state: "West Virginia",
        abbreviation: "WV"
    },
    {
        state: "Wisconsin",
        abbreviation: "WI"
    },
    {
        state: "Wyoming",
        abbreviation: "WY"
    }
]

export const  ENTITYTYPE = [
    {
        etype: "Business Associate"
    },
    {
        etype: "Health Plan"
    },
    {
        etype: "Healthcare Provider"
    }
]

export const DATE = [
    {
        month: "January",
        abb: "JAN"
    },
    {
        month: "Febuary",
        abb: "FEB"
    },
    {
        month: "March",
        abb: "MAR"
    },
    {
        month: "April",
        abb: "APR"
    },
    {
        month: "May",
        abb: "MAY"
    },
    {
        month: "June",
        abb: "JUN"
    },
    {
        month: "July",
        abb: "JUL"
    },
    {
        month: "August",
        abb: "AUG"
    },
    {
        month: "September",
        abb: "SEPT"
    },
    {
        month: "October",
        abb: "OCT"
    },
    {
        month: "November",
        abb: "NOV"
    },
    {
        month: "December",
        abb: "DEC"
    }
]

export const YEAR = [
    {
        year: "2020"
    },
    {
        year: "2021"
    },
    {
        year: "2022"
    }
]

export const  BREACHTYPE = [
    {
        type: "Unauthorized Access/Disclosure"
    },
    {
        type: "Hacking/IT Incident"
    },
    {
        type: "Improper Disposal"
    },
    {
        type: "Theft"
    },
    {
        type: "Loss"
    }
]

export const LOCATION = [
    {
        location: "Network Server"
    },
    {
        location: "Email"
    },
    {
        location: "Other Portable Electronic Device"
    },
    {
        location: "Paper/Films"
    },
    {
        location: "Laptop, Other"
    },
    {
        location: "Electronic Medical Record"
    },
    {
        location: "Laptop"
    },
    {
        location: "Desktop Computer"
    },
    {
        location: "Other"
    }
];










