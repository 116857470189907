import React from 'react';

import weboutput from "../json/weboutput.json";


const NewsPanel = () => {
  return (
    <div className = 'container-news'>

      <div className='header'>
        <h3 className ='header-news'> News Panel </h3>
      </div>

      {weboutput.article.slice(23,28).map(( {photo, name, description, link}) => {
        return (
          <div className = 'article'>
            <div className='article-photo'> 
              <img src = {photo} alt="pic placement" width="40px" height="40px"></img>
            </div>
            <a href={link} target="_blank" >
              <div className='article-title' > {name} </div>
            </a>
            <div className='article-description'> {description} </div> 
          </div>
        );
      })}
      
    </div>
  )
};
export default NewsPanel;

