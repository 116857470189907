import React from "react";
import { useState } from "react";
import {XAxis, YAxis, CartesianGrid, Legend, Tooltip, BarChart, Bar} from "recharts";

const barChartData1 = [
  {City: "Spokane", Cases: 67 },
  {City: "Portland", Cases: 20},
  {City: "Seattle",Cases: 80},
  {City: "Idaho",Cases: 70},
  {City: "Newyork",Cases: 59 },
  {City: "Chicago",Cases: 22 },
  {City: "Phoenix", Cases: 61 },
  {City: "Austin", Cases: 34},
  {City: "Nashville",Cases: 33},
  {City: "San Diego",Cases: 86},
  {City: "San Antonio",Cases: 49 },
  {City: "Boston",Cases: 25 },
  {City: "Los Angeles", Cases: 44 },
  {City: "San Jose", Cases: 14},
  {City: "Denver",Cases: 68},
  {City: "San Francisco",Cases: 54},
  {City: "Houston",Cases: 67 },
  {City: "Dallas",Cases: 41 },
  {City: "Las Vegas", Cases: 38 },
  {City: "Atlanta", Cases: 88},
  {City: "New Orleans",Cases: 23},
  {City: "Oklahoma",Cases: 97},
  {City: "Miami",Cases: 38 },
  {City: "Boise",Cases: 56 },
  ];
  const barChartData2 = [
    {City: "Spokane", Cases: 76 },
    {City: "Portland", Cases: 20},
    {City: "Seattle",Cases: 8},
    {City: "Idaho",Cases: 7},
    {City: "Newyork",Cases: 95 },
    {City: "Chicago",Cases: 22 },
    {City: "Phoenix", Cases: 16 },
    {City: "Austin", Cases: 43},
    {City: "Nashville",Cases: 33},
    {City: "San Diego",Cases: 68},
    {City: "San Antonio",Cases: 94 },
    {City: "Boston",Cases: 52 },
    {City: "Los Angeles", Cases: 44 },
    {City: "San Jose", Cases: 41},
    {City: "Denver",Cases: 86},
    {City: "San Francisco",Cases: 45},
    {City: "Houston",Cases: 76 },
    {City: "Dallas",Cases: 14 },
    {City: "Las Vegas", Cases: 83 },
    {City: "Atlanta", Cases: 88},
    {City: "New Orleans",Cases: 32},
    {City: "Oklahoma",Cases: 79},
    {City: "Miami",Cases: 83 },
    {City: "Boise",Cases: 65 },
    ];
    const barChartData3 = [
      {City: "Spokane", Cases: 21 },
      {City: "Portland", Cases: 8},
      {City: "Seattle",Cases: 65},
      {City: "Idaho",Cases: 45},
      {City: "Newyork",Cases: 34 },
      {City: "Chicago",Cases: 56 },
      {City: "Phoenix", Cases: 33 },
      {City: "Austin", Cases: 98},
      {City: "Nashville",Cases: 66},
      {City: "San Diego",Cases: 43},
      {City: "San Antonio",Cases: 63 },
      {City: "Boston",Cases: 5 },
      {City: "Los Angeles", Cases:88 },
      {City: "San Jose", Cases: 42},
      {City: "Denver",Cases: 17},
      {City: "San Francisco",Cases: 36},
      {City: "Houston",Cases: 76 },
      {City: "Dallas",Cases: 63 },
      {City: "Las Vegas", Cases: 98 },
      {City: "Atlanta", Cases: 76},
      {City: "New Orleans",Cases: 45},
      {City: "Oklahoma",Cases: 49},
      {City: "Miami",Cases: 76 },
      {City: "Boise",Cases: 21 },
  ];

  
const YearlyPanels = () => {
  const [data, setData] = useState(barChartData1);
  const [year, setYear] = useState(2020);
  
  return (
    <div className='container-yearly'>
      <div className='buttonHeader'> 
        <button className='button1' onClick={ () => {setData(barChartData1) ; setYear(2020)}}> 2020 </button>
        <button className='button1' onClick={ () => {setData(barChartData2) ; setYear(2021)}}> 2021 </button>
        <button className='button1' onClick={ () => {setData(barChartData3) ; setYear(2022)}}> 2022 </button>
        <button className='button1' onClick={ () => {setData(barChartData3) ; setYear(2022)}}> Show All </button>
      </div>
      <div className="yrly-bars"> 
        <div className="bar-2020" >
          <h3 style={{color:"black"}}>CASES BY STATE {year} </h3>
          <BarChart data= {data} width={840} height={200} >
            <CartesianGrid strokeDasharray="2 2"/>
            <Tooltip />
            <Bar dataKey="Cases" fill="#1C4E80" />
            <XAxis dataKey ="City" interval="preserveStartEnd" tickFormatter={(value)=> value}/>
            <YAxis />
            <Legend />
          </BarChart>
        </div>
      </div>
    </div>
  )
};
export default YearlyPanels;