import React from 'react';
import './index.css';

import HeaderPanel from './panels/HeaderPanel';
import FilterPanel from './panels/FilterPanel';
import NewsPanel from './panels/NewsPanel';
import PiePanel from './panels/PiePanel';
import UpdatesPanel from './panels/UpdatesPanel';
import YearlyPanels from './panels/YearlyPanel';

const Dashboard = () => {
  return (
    <div className='panels'>
      <div className='panel-header'>
        < HeaderPanel />
      </div>
      <div className = 'panel-news'>
        < NewsPanel />
      </div>
      <div className='panel-filter'>
        < FilterPanel />
      </div>
      <div className='panel-updates'>
        < UpdatesPanel />
      </div>
      <div className='panel-yearly'>
        < YearlyPanels />
      </div>
      <div className='panel-pie'>
        < PiePanel />
      </div>
    </div>
  )
};
export default Dashboard;