import React from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  {Breach: "Hacking/IT Incident", value: 78, fill: "#1C4E80"},
  {Breach: "Loss", value: 23, fill: "#0091D5"},
  {Breach: "Unauthorized Access/Disclosure", value: 45, fill: "#2e7aa4"},
  {Breach: "Theft", value: 65, fill: "#e3342f"}
 
];

const PiePanel = () => {
  return (
    <div className='container-pie'>
      <div className='breach-title'>
        <h3> Types of Breaches </h3>
      </div>
      <div className='pieGraph'>
            <PieChart width={200} height={200} >
              <Pie data={data} dataKey="value" outerRadius={100} innerRadius={10} fill="#fff" 
              isAnimationActive = {false} nameKey = "Breach"/>
              <Tooltip/>
            </PieChart>
      </div>
    </div>

  )
};
export default PiePanel;