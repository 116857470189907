import { STATES, LOCATION, ENTITYTYPE, DATE, YEAR, BREACHTYPE } from "../json/filters";
import jsonData from "../json/jsonData.json";
import { useState } from "react";
//import fetchTest from "../server";

//Holds the criteria that is selected for filtering
//Designated for each catergory of data that has appropriate options for filtering
let filterOptions={
  entityState: [],
  entityName: [],
  entityType: [],
  dateRange: [],
  affectedRange: [],
  inYear: [],
  breachType: [],
  breachLocation: []
}
  
  let updated = [];

const FilterPanel = () => {
    //fetchTest();

    // Upon loading the website, data is the raw data being extracted from the OCR site.
    // data is set to a default of that data from the json object "breaches", 
    // useState is this default method. 
    // data is then iterated through in code below to display onto site 
    // setData overrides the default information initialized in data to be used in display
    const [data, setData] = useState(jsonData.breaches);

    // creates an array that hold the check state for each checkbox under state filter options on the filter display
    // filterState will hold the inital state of the checkboxes which will be "false" to indicate unchecked
    // an array the size of the amount of options under "STATE" will be created to keep track of what checkboxes are "clicked" "unclicked"
    // setFilterState : updates value within array to "true" or "false" respectively 
    const [filterState, setFilterState] = useState(
      new Array(STATES.length).fill(false)
    );
 
    // array of checked state (T/F) for Entity Type options 
    const [filterEtype, setFilterEtype] = useState(
      new Array(ENTITYTYPE.length).fill(false)
    );
    
    // array of checked state (T/F) for Breach Type options
    const [filterBtype, setFilterBtype] = useState (
      new Array(BREACHTYPE.length).fill(false)
    );

    //array of checked state (T/F) for Breach Location options
    const [filterLocation, setFilterLocation] = useState (
      new Array(LOCATION.length).fill(false)
    );
    
    // function to change whether check box is checked or unchecked and run filtering function
    // updatedCheckState : itereate through filterState array to set to True or False
    // item : 
    // index : postition in array which matches position of the checkbox
    // setFilterState : with respective true/false and updates it in the array 
    // Calls our filter function check is valid with parameters 
    
    const handleOnChangeS = (position, type, val) => {
      const updatedCheckedState = filterState.map((item, index) =>
        index === position ? !item : item
      );

      setFilterState(updatedCheckedState);
      console.log(updatedCheckedState[position]);
      // type = exact name of type of data we wish to compare in jsonData (ie. entityState )
      // val = the name of the checkbox value type we wish to see (ie. "WA" == washington )
      // updatedCheckedState[position] : the value within the array that indicates that checkbox returning its value (either boolean T/F); 
      checkIsValid(type, val, updatedCheckedState[position]);
    };
 
    // Entity Type check 
    // update whether checkbox is checked or unchecked denoted by true and false 
    // call the filtering function 
    const handleOnChangeE = (position, type, val) => {
      const updatedCheckedState = filterEtype.map((item, index) =>
          index === position ? !item : item
        );

      setFilterEtype(updatedCheckedState);
      console.log(updatedCheckedState[position]);
      checkIsValid(type, val, updatedCheckedState[position]);
    }

    // Breach Type check
    // update whether checkbox is checked or unchecked denoted by true and false
    // calls filtering function 
    const handleOnChangeB = (position, type, val) => {
      const updatedCheckedState = filterBtype.map((item, index) =>
          index === position ? !item : item
        );
       setFilterBtype(updatedCheckedState);
       console.log(updatedCheckedState[position]);
       checkIsValid(type, val, updatedCheckedState[position]);
      }
    
    const handleOnChangeL = (position, type, val) => {
      const updatedCheckedState = filterLocation.map((item, index) =>
          index === position ? !item : item
        );

      setFilterLocation(updatedCheckedState);
      console.log(updatedCheckedState[position]);
      checkIsValid(type, val, updatedCheckedState[position]);
    }
    // Data Type that is being checked or unchecked will be added or removed from array in filterObjects
    // Arrays will hold the data type (ie "WA") that will be used for comparison in filter function
    // Data Type : what is being compared (ie entityState)
    // Value : the specific value we are compating (ie "WA")
    // updatedCheck : boolean (true if checked; false if unchecked)
    function AddRemove(dataType, value, updatedCheck){
      // Check box is Checked 
      // will add value into appropriate array
      if(updatedCheck===true){ 
        //console.log("in push if"); 
        filterOptions[dataType].push(value);
      }
      // Check box is Unchecked 
      else if(updatedCheck===false){ 
        //console.log("in splice else if"); 
        //Iterate 
        //Remove value from the array
        for(let k=0;k<filterOptions[dataType].length;k++){
          if(filterOptions[dataType][k]===value){
            filterOptions[dataType].splice(k,1);
          }
        } 
        //console.log(filterOptions[dataType]);
      }
      //Error for debugging
      else{console.log("Could not determine whether box has been checked [filterJSON(dataType, val, updatedCheck)].")}
    }
    
    //Filter Functionality 
    //For every checkbox clicked, this will look through the jsonData with the data from the OCR and compare it with 
    //the selected criteria 
    function checkIsValid(dataType, value, updateCheck){
    
      let temp=[];
      let stateIsValid=false;
      let typeIsValid=false;
      let breachIsValid = false;
      let locationIsValid = false;
      let thingIt=0;
      AddRemove(dataType,value,updateCheck);

      for(let i = 0; i <jsonData.breaches.length; i++){

        //console.log("in first for loop")
        //console.log(filterOptions.entityState[0]);
        //*** Looking through filterOptions.entityState to see if the jsonData.entityState matches anything in there. ***//
        if(typeof filterOptions.entityState[0]==='undefined'){ console.log("Undefined at location 0 in filterOptions.entityState."); stateIsValid=true;} // If there is nothing in the array, nothing has been checked, so nothing should be filtered.
        else{
          for(let j=0;j<filterOptions.entityState.length;j++){
            
            if(jsonData.breaches[i].entityState===filterOptions.entityState[j]){
              stateIsValid=true;
            }
          }
        }

        //*** Now looking through entityType. ***//
        if(typeof filterOptions.entityType[0]=== 'undefined'){ console.log("Undefined at location 0 in filterOptions.entityType."); typeIsValid=true;} // If there is nothing in the array, nothing has been checked, so nothing should be filtered.
        else{
          for(let j=0;j<filterOptions.entityType.length;j++){
            
            if(jsonData.breaches[i].entityType===filterOptions.entityType[j]){
              typeIsValid=true;
            }
          }
        }

        //looking through breach type 
        if(typeof filterOptions.breachType[0]=== 'undefined'){ console.log("Undefined at location 0 in filterOptions.breachType."); breachIsValid=true;} // If there is nothing in the array, nothing has been checked, so nothing should be filtered.
        else{
          for(let j=0;j<filterOptions.breachType.length;j++){
            
            if(jsonData.breaches[i].breachType===filterOptions.breachType[j]){
              breachIsValid=true;
            }
          }
        }

        //Looking through breach location
        if(typeof filterOptions.breachLocation[0]=== 'undefined'){ console.log("Undefined at location 0 in filterOptions.breachLocation."); locationIsValid=true;} // If there is nothing in the array, nothing has been checked, so nothing should be filtered.
        else{
          for(let j=0;j<filterOptions.breachLocation.length;j++){
            
            if(jsonData.breaches[i].breachLocation===filterOptions.breachLocation[j]){
              locationIsValid=true;
            }
          }
        }

        if(stateIsValid===true&&typeIsValid===true&&breachIsValid===true&&locationIsValid===true){

          temp[thingIt]=jsonData.breaches[i];
          //console.log(temp);
          thingIt+=1;
        }

        breachIsValid = false;
        stateIsValid = false;
        typeIsValid = false;
        locationIsValid = false;
      }
      
      //console.log(temp);
      //updated = temp;
      setData(temp);
    }

return (
<div className='container-filter'>

<div className = 'container-filterCheckBox'>
  <h3 className='filter-OCR'> OCR Wall Of Shame </h3>
  <div className="filterChBx">
    <div className="dropdown">
    <button className="dropdown-btn"> Entity Name </button>
    </div>
  </div>

  <div id="state" className="filterChBx">
    <div className="dropdown"> 
      <button className="dropdown-btn"> State </button>
        <div className="dropdown-items">
          <ul className="items">
            {STATES.map( ({abbreviation}, index) => { 
              return (
                <li><input type = "checkbox" checked={filterState[index]} onChange={ () => handleOnChangeS(index, "entityState", abbreviation)} value={abbreviation}/> {abbreviation} </li>  
              );
            })}
          </ul>
      </div>
    </div>
  </div>
  <div id="type" className="filterChBx">
    <div className="dropdown"> 
      <button className="dropdown-btn"> Entity Type </button>
        <div className="dropdown-items">
          <ul className="items">
            {ENTITYTYPE.map( ({etype}, index) => { 
              return (
                <li><input type = "checkbox" checked={filterEtype[index]} onChange={ () => handleOnChangeE(index, "entityType", etype)} value={etype}/> {etype} </li>  
              );
            })}
          </ul>
      </div>
    </div>
  </div>
  <div className="filterChBx">
    <div className="dropdown">
    <button className="dropdown-btn"> Indiv. Affected </button>
    </div>
  </div>
  <div id="month" className="filterChBx">
    <div className="dropdown"> 
      <button className="dropdown-btn"> Month Submitted </button>
        <div className="dropdown-items">
          <ul className="items">
            {DATE.map( ({abb}) => { 
              return (
                <li><input type = "checkbox" value={abb}/> {abb} </li>  

              );
            })}
          </ul>
      </div>
    </div>
  </div>
  <div id="year" className="filterChBx">
    <div className="dropdown"> 
      <button className="dropdown-btn"> Year Submitted </button>
        <div className="dropdown-items">
          <ul className="items">
            {YEAR.map( ({year}) => { 
              return (
                <li><input type = "checkbox" value={year}/> {year} </li>  
              );
            })}
          </ul>
      </div>
    </div>
  </div>

  <div id="breach" className="filterChBx">
    <div className="dropdown"> 
      <button className="dropdown-btn"> Breach Type </button>
        <div className="dropdown-items">
          <ul className="items">
            {BREACHTYPE.map( ({type}, index) => { 
              return (
                <li><input type = "checkbox" checked={filterBtype[index]} onChange={ () => handleOnChangeB(index, "breachType", type)} value={type}/> {type} </li>  
              );
            })}
          </ul>
      </div>
    </div>
  </div>

  <div id="location" className="filterChBx">
    <div className="dropdown"> 
      <button className="dropdown-btn"> Location of Breach </button>
        <div className="dropdown-items">
          <ul className="items">
            {LOCATION.map( ({location} , index) => { 
              return (
                <li><input type = "checkbox" checked={filterLocation[index]} onChange={ () => handleOnChangeL(index, "breachLocation", location)} value={location}/> {location} </li>  
              );
            })}
          </ul>
      </div>
    </div>
  </div>
</div>

  {data.map( ({entityName, entityState, entityType, individualsAffected, breachDate, breachType, breachLocation} ) => {
  return (
    <div className='view-filter'>
    <div className='breach'>
      <div className='iName' id="view-filterHeader" > {entityName} </div>
      <div className='iState' id="view-filterHeader" > {entityState} </div>
      <div className='iType' id="view-filterHeader" > {entityType} </div>
      <div className='iAffected' id="view-filterHeader" > {individualsAffected} </div>
      <div className='iDate' id="view-filterHeader" > {breachDate} </div>
      <div className='iBreach' id="view-filterHeader"> {breachType} </div>
      <div className='iLocation' id="view-filterHeader" > {breachLocation} </div>
      </div>
    </div>
  ); 
  })}
</div>
)
};
export default FilterPanel;